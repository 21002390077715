.add_contact_main {
  padding: 2rem;
}

.add_contact_item {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

.add_contact_row_actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.add_contact_row_actions span {
  width: 100%;
  padding: 0.6rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
}

.add_contact_row_actions svg {
  font-size: 1.2rem;
}

.add_contact_row_actions span:nth-child(1) {
  background-color: var(--green500);
  color: white;
}

.add_contact_row_actions span:nth-child(2) {
  background-color: var(--red500);
  color: white;
}

.add_contact_table {
  background-color: white;
  min-height: 400px;
  border-radius: 10px;
}

@media (max-width: 430px) {
  .add_contact_main {
    padding: 0;
  }

  .add_contact_item {
    padding: 0.6rem;
    gap: 0.6rem;
  }
}

.add_contact_header_actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
