@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto";
  background-color: #f5f5f5;
}

.wrapper {
  max-width: 756px;
  margin: 0 auto;
}
:root {
  --green100: #effed9;
  --green500: #569203;
  --yellow100: #ffedd5;
  --yellow500: #ffa62b;
  --red100: #fbe7ee;
  --red500: #d81159;
  --blue500: #1d4e89;
  --blue600: #173e6e;
  --gray100: #ebebeb;
  --gray50: #fbfbfb;
  --gray500: #a0a0a0;
}

input,
select,
label,
button,
textarea {
  font-family: inherit;
}
audio {
  display: block;
  max-width: 100%;
}

img {
  display: block;
  max-width: 100%;
}

table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
}

td,
th {
  padding: 0.8rem !important;
  font-size: 14px;
}

thead {
  background-color: var(--gray50);
}

th {
  text-align: left !important;
  text-transform: capitalize;
}

tr:nth-of-type(even) {
  background-color: var(--gray50);
}

tr:hover {
  background-color: var(--gray50);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="date"] {
  width: 100%;
  outline: none;
  border: 1px solid #ccc;
  padding: 0.6rem;
  border-radius: 4px;
  &:focus {
    border-color: var(--blue500);
  }
}

a {
  text-decoration: none;
}
