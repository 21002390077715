.saved_contact_list {
  width: 100%;
  background-color: white;
}

.dispatch_contact_modal_main {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.saved_contact_list_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.saved_contact_avail {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.saved_contact_avail span {
  padding: 0.6rem;
  color: var(--yellow500);
  font-weight: 500;
  font-size: 14px;
}
.dispatch_contact_modal_items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.dispatched {
  border-color: var(--blue500);
  cursor: pointer;
}
.dispatched span {
  color: var(--blue500);
  font-size: 14px;
}

.add {
  background-color: var(--blue500);
  cursor: pointer;
}

.add span {
  color: white;
}
.add:hover {
  background-color: var(--blue600);
}

.toggle_color {
  background-color: var(--blue500);
}

.toggle_color span {
  color: white;
}

.loading {
  color: gray;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: capitalize;
}
