.header_logo {
  height: 32px;
  overflow: hidden;
}
.header_logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.header_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 1rem;
  background-color: #f5f5f5;
  position: sticky;
  top: 0;
}


