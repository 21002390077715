.auth_main {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 6px;
}
.auth_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.auth_item label {
  color: gray;
  text-transform: capitalize;
  font-size: 14px;
}

.auth_options {
  padding: 0.6rem;
  background-color: var(--gray50);
  color: black;
  text-align: center;
}

.auth_header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.2rem;
}
.auth_header P {
  font-weight: 600;
  font-size: 1.2rem;
}
.auth_header span {
  color: gray;
  text-transform: capitalize;
  font-size: 14px;
}

.auth_verification {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 1rem 0;
}
.auth_verification p {
  line-height: 1.6;
  font-size: 14px;
  color: gray;
}

.auth_verification span {
  font-size: 1.4rem;
  font-weight: 600;
}
.auth_header svg {
  font-size: 2rem;
  color: var(--yellow500);
}

.user_list_main {
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 4px;
}

.user_account_list_main {
  padding: 1rem 0.5rem;
}

.users_list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.user_report_link {
  width: 100%;
  padding: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--yellow500);
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  &:active {
    background-color: white;
  }
}
