.ui_input,
.ui_textarea {
  outline: none;
  border: none;
  border: 1px solid var(--gray500);
  border-radius: 4px;
  padding: 0.8rem 0.4rem;
  width: 100%;
  transition: all 0.2s;
}

.ui_input::placeholder {
  color: #ccc;
  text-transform: capitalize;
  font-size: 14px;
  font-style: oblique;
}

.ui_input:hover,
.ui_input :focus {
  border-color: var(--blue500);
}

.ui_textarea {
  resize: none;
  width: 100%;
}
.ui_textarea:focus {
  border-color: var(--blue500);
}
.ui_button {
  width: 100%;
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 0.6rem;
  background-color: var(--blue500);
  color: white;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background-color: var(--blue600);
  }
  &:active {
    background-color: var(--blue500);
  }
}

.select_section {
  position: relative;
}

.select_main {
  width: 100%;
  background-color: white;
  border: 1px solid var(--gray500);
  border-radius: 4px;
  overflow: hidden;
}
.select_main:focus-within {
  border-color: var(--blue500);
}

.select_main:hover {
  border-color: var(--blue500);
}
.select_main input {
  width: 100%;
  padding: 0.6rem;
  outline: none;
  border: none;
}

.select_main input::placeholder {
  font-style: oblique;
  color: #ccc;
  font-size: 14px;
  text-transform: capitalize;
}

.ui_inner_select {
  display: flex;
  flex-direction: column;
  padding: 0.4rem 0;
  background-color: white;
  min-height: 80px;
  position: absolute;
  width: 100%;
  z-index: 100;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ui_inner_select span {
  padding: 0.4rem;
  font-size: 14px;
  &:hover {
    background-color: var(--gray100);
    cursor: pointer;
  }
}

.select_placeholder {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: default !important;
  text-transform: capitalize;
  color: var(--red500);
  &:hover {
    background-color: white !important;
  }
}
