.box {
  background-color: white;
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: 1rem;
}

.captlize {
  text-transform: capitalize;
}

.small {
  font-size: 14px;
}

.gray {
  color: var(--gray500);
}

td[name="other"] {
  color: var(--yellow500);
}
td[name="facebook"] {
  color: var(--blue500);
}

.bold {
  font-weight: 500;
}

.red {
  color: var(--red500);
}

.rounded_icon {
  font-size: 1.6rem;
  padding: 0.2rem;
  border-radius: 50%;
  cursor: pointer;
}

.rounded_icon:hover {
  background-color: var(--gray100);
}

.yellow {
  color: var(--yellow500);
}

.green {
  color: var(--green500);
}

.blue {
  color: var(--blue500);
}

.item_pair_row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.item_pair_column {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

span[name="interested"] {
  color: var(--yellow500);
  font-size: 14px;
  text-transform: capitalize;
}

.item_pair_row a {
  text-decoration: none;
}

.border_bottom {
  border-bottom: 1px solid var(--gray100);
}
.padding_bottom {
  padding-bottom: 1rem;
}

.title {
  font-weight: 600;
  font-size: 1rem;
  display: inline-block;
  padding: 0.4rem 0;
}

.centered {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: capitalize;
  color: red;
  font-weight: 500;
}

.padding_top {
  padding-top: 1rem;
  display: inline-block;
}


