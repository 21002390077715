.call_report_main {
  background-color: var(--gray50);
  padding: 0 1rem;
}
.call_reports_contacts {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}
.call_report_single_contact {
  background-color: white;
  padding: 1rem 0.8rem;
  border-radius: 4px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2);
}
