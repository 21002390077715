.link_call,
.link_whatsapp,
.call_history,
.call_feedback {
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 1.4rem;
  padding: 0.4rem;
  color: white;
}

.link_call {
  background-color: var(--blue500);
}
.link_whatsapp {
  background-color: var(--green500);
}
.call_history {
  background-color: var(--yellow500);
}

.call_feedback {
  background-color: var(--gray100);
  color: var(--blue500);
}

.user_call_single_main {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  padding: 1rem;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #ccc;
  margin: 1rem 0;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.27);
}

.user_contacts_main {
  padding: 1rem;
}

.call_feedback_type {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  gap: 0.6rem;
  padding: 0.8rem 0;
}
.call_feedback_type span {
  padding: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 10px;
}

.filled {
  background-color: var(--green500);
  color: white;
  cursor: pointer;
}
.white {
  background-color: white;
  cursor: pointer;
}

.contact_types {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 0.6em;
}

.contact_types span {
  font-size: 10px;
  padding: 0.4rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  text-align: center;
  text-transform: capitalize;
}

.filter_date_picker {
  outline: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.4rem;
  width: 100%;
  max-width: 100%;
  font-size: 12px;
}
.filter_date_picker:focus {
  border-color: var(--blue500);
}

.contact_filter {
  padding: 1rem 0;
}
.contact_filter label {
  color: var(--gray500);
  font-size: 14px;
}

.filter_contact_count span {
  text-align: center;
  padding: 0.4rem;
  width: 100%;
  background-color: var(--yellow500);
  border-radius: 4px;
  text-transform: capitalize;
  font-size: 14px;
}

.filter_contact_count span:first-of-type {
  background-color: white;
  border: 1px solid #ccc;
}
.filter_contact_count span:last-child {
  background-color: var(--green500);
  color: white;
}

.user_contact_header {
  background-color: var(--gray100);
  padding: 1rem 0.4rem;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.filter_search_main {
  width: 100%;
  display: flex;
  border: 1px solid transparent;
  border-radius: 4px;
  align-items: center;
  overflow: hidden;
  margin: 0.6rem 0;
}
.filter_search_main input {
  flex: 1;
  outline: none;
  border: none;
  font-size: 14px;
  padding: 0.6rem 0.8rem;
}
.filter_search_main svg {
  background-color: var(--green500);
  color: white;
  font-size: 1.2rem;
}

.filter_search_main span {
  height: 100%;
  background-color: var(--green500);
  padding: 0.6rem 0;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout_action,
.contact_user_name {
  padding: 0.4rem;
  text-align: center;
  background-color: var(--blue500);
  border-radius: 4px;
  text-transform: capitalize;
  color: white;
}

.contact_user_name {
  background-color: white;
  border: 1px solid #ccc;
  color: black;
}

.user_call_header_icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.user_call_header_icon span {
  font-size: 1.2rem;
}

.dailed_sub_filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1rem;
}
.dailed_sub_filter span {
  background-color: var(--blue500);
  color: white;
  padding: 0.4rem;
  border-radius: 4px;
  font-size: 12px;
}

.float_actions {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.float_add_contact {
  background-color: seagreen;
  color: white;
  height: 2.2rem;
  width: 2.2rem;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.float_add_contact svg {
  font-size: 1.4rem;
}

.accounts {
  background-color: var(--yellow500);
}

.contact_projects {
  padding: 1rem 0;
}

.project {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 0.4rem;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  &:hover {
    background-color: #f5f5f5;
    transition: all 0.2s;
  }
}
.project svg {
  font-size: 1.2rem;
}
.project span {
  text-transform: capitalize;
}

.projects {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
}
.projects span {
  padding: 0.4rem;
  border-bottom: 1px solid #ccc;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
    transition: all 0.2s;
  }
}
