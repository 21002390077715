.call_detail_section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--gray50);
  padding: 1rem;
  /* width: 90%; */
  /* margin: 0 auto; */
  border-radius: 6px;
}

.call_detail_history {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.call_history_each {
  background-color: white;
  padding: 1rem;
  border-radius: 6px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.27);
}

.call_detail_message {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.call_detail_message p {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.call_detail_header {
  background-color: var(--gray100);
  padding: 0.6rem;
  margin-bottom: 1rem;
}
.call_detail_header svg {
  font-size: 1.2rem;
}

.audio_player {
  padding: 0.8rem 0;
}
